import { updateSearchBarOptions as searchBarUpdateSearchBarOptions } from "./search-bar.js";

var checkboxSelector = '.js-accommodation-only-checkbox';
var radioButtonSelector = 'input[type="radio"][name="holidayPackageType"]';
var disabledAfterUncheckedAttribute = 'data-accom-only-disable-after-unchecked';

function init() {
	if (!$(checkboxSelector).length) {
		return;
	}
	attachEvents();
	watchForSearchOptionsReceived();
}

function attachEvents() {
	$(checkboxSelector).off('change', handleCheckboxChange).on('change', handleCheckboxChange);
	$(radioButtonSelector).off('change', handleRadioButtonChange).on('change', handleRadioButtonChange);
}

function watchForSearchOptionsReceived() {
	window.site.doc.on("searchOptionsReceived", function(data) {
		if (data.options.AccommodationOnlyAvailable && data.options.EnableDepartureAirports) {
			enableAccomodationOnlyCheckbox(data.wrapper);
		} else {
			disableAccomodationOnlyCheckbox(data.wrapper);
		}
		var checkbox = $(data.wrapper).find(checkboxSelector);
		if (!checkbox.is(':checked') && data.options.AccommodationOnlyAvailable && data.options.IsAccommodationOnlySearch) {
			checkbox.prop('checked', true);
			updateSubFilter(checkbox);
		} else if (checkbox.is(':checked') && !data.options.IsAccommodationOnlySearch && !data.options.AccommodationOnlyAvailable) {
			checkbox.removeAttr('checked');
			updateSubFilter(checkbox);
		}

		propagateChanges(checkbox);
	});
}

function updateSubFilter(checkboxElement) {
	if (checkboxElement.is(':checked')) {
		$('input[name="holidayPackageType"][value="accommodationOnly"]').prop('checked', true);
	} else {
		$('input[name="holidayPackageType"][value="accommodationOnly"]').prop('checked', false);
		$('input[name="holidayPackageType"][value=""]').prop('checked', true);
		if (checkboxElement.attr(disabledAfterUncheckedAttribute)) {
			disableAccomodationOnlyCheckbox(checkboxElement.closest('.js-folks-selector-wrapper'));
		}
	}

}

function propagateChanges(checkboxElement) {
	var nearestAirportList = checkboxElement.parent().parent().parent().next().find('.js-airport-picker-facade');
	if (checkboxElement.is(':checked')) {
		nearestAirportList.addClass('hello').prop('disabled', true);
	} else {
		nearestAirportList.removeClass('js-disabled-input').prop('disabled', false);
	}
}

function handleCheckboxChange(checkboxElementToChange) {

	var checkboxElement = $((checkboxElementToChange || { target: this }).target);
	updateSubFilter(checkboxElement);

	searchBarUpdateSearchBarOptions();

	if (!shouldUpdatePackageRadios(checkboxElement)) {
		return;
	}

	propagateChanges(checkboxElement);
}

function shouldUpdatePackageRadios(checkboxElement) {
	return checkboxElement.closest('.js-dynamic-search').length;
}

function enableAccomodationOnlyCheckbox(wrapper) {
	$(wrapper).find(checkboxSelector).addClass('js-disabled-input').prop('disabled', false).removeAttr(disabledAfterUncheckedAttribute);

}

function disableAccomodationOnlyCheckbox(wrapper) {
	var checkbox = $(wrapper).find(checkboxSelector);
	if (checkbox.is(':checked')) { // if already checked mark for disabling after being unchecked
		checkbox.attr(disabledAfterUncheckedAttribute, 'true');
	} else {
		checkbox.removeClass('js-disabled-input').prop('disabled', true);
	}
}

function handleRadioButtonChange() {
	var radioValue = $(radioButtonSelector + ':checked').val()
	if (radioValue === "accommodationOnly") $(checkboxSelector).prop('checked', true)
	else $(checkboxSelector).prop('checked', false)
}

export default init;
export { attachEvents }
